<template>
  <div>
    <div class="container-fluid">
      <PageTitle />

      <template v-if="isList">
        <card-list
          :filter.sync="filter"
          :dataListing.sync="data"
          :idKey="idKey"
          :fields="fields"

          :no-btn-mass="true"
          :no-multi-select="true"
        >
        <template #aksi="data">
          <div v-if="!moduleRole('ViewOnly')" class="btn-toolbar">
            <b-dropdown no-flip>
              <template #button-content>
                <i class="icon-menu"></i>
              </template>
              <b-dropdown-item :to="{name:$route.name, params:{pageSlug:data.scope.item[idKey]}}">
                Ubah Data
              </b-dropdown-item>
              <b-dropdown-item @click="doDelete(data.scope.index, data.scope.item)">Hapus</b-dropdown-item>
            </b-dropdown>
            <b-dropdown 
              no-flip 
              variant="info" 
              class="ml10" toggle-class="absolute-button"
            >
              <template #button-content>
                <i class="ti-exchange-vertical" v-b-tooltip.hover 
              title="Ganti Status"></i>
              </template>
              <b-dropdown-item 
                :class="{markedDropdown:data.scope.item[statusKey]==1}"
                @click="doChangeStatus(data.scope.index, data.scope.item, 1)"
              >
                Active
              </b-dropdown-item>
              <b-dropdown-item 
                :class="{markedDropdown:data.scope.item[statusKey]==2}"
                @click="doChangeStatus(data.scope.index, data.scope.item, 2)"
              >
                Inactive
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <span v-else>-</span>
        </template>
        <template #mo_name="data">
          {{data.scope.value || '-'}}
        </template>
        <template #ms_is_active="data">
          <LabelStatus :status="data.scope.item[statusKey]" />
        </template>
        <template #ms_kategori="data">
          {{ data.scope.value == 'kadiv' ? 'Kadiv MR' : 'PJRTU'}}
        </template>
        </card-list>
      </template>
      <div 
        v-else 
        class="card"
      >
      <div class="card-body">
        <h5 class="card-title mb-3">{{isAdd ? "Tambah": "Ubah"}} Tanda Tangan</h5>
        
        <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
          <form @submit.prevent="handleSubmit(doSubmitCRUD)">
            <b-row>
              <b-col md=6>
                <b-form-group>
                  <template #label>
                    Kategori Jabatan <span class="text-danger mr5">*</span>
                  </template>
                  <VSelect 
                    v-model="row.ms_kategori" 
                    placeholder="-- Pilih Satu --" 
                    :options="mrKategori"
                    :disabled="!isAdd"
                    :reduce="v=>v.value" 
                  />
                  <VValidate 
                    name="Kategori Jabatan" 
                    v-model="row.ms_kategori" 
                    :rules="mrValidation.ms_kategori" 
                  />
                </b-form-group>
              </b-col>
              <b-col md=1>
              </b-col>
              <template>
              <b-col md=6>
                <b-form-group>
                  <template #label>
                    Kantor <span class="text-danger mr5">*</span>
                  </template>
                  <VSelect 
                    v-model="row.ms_office_id" 
                    placeholder="-- Pilih Satu --" 
                    :options="mrKantor"
                    :disabled="!isAdd || row.ms_kategori == 'kadiv'"
                    :reduce="v=>v.value" 
                  />
                  <VValidate 
                    name="Kantor" 
                    v-model="row.ms_office_id" 
                    rules="required" 
                  />
                </b-form-group>
              </b-col>
              </template>
            </b-row>
            <b-row>
              <template v-if="isPusat && row.ms_kategori == 'pjrtu'">
                <b-col md=6>
                  <b-form-group>
                    <template #label>
                      Group <span class="text-danger mr5">*</span>
                    </template>
                    <b-overlay
                      :show="loadingDivisi"
                    >
                      <VSelect 
                        v-model="row.ms_unit_kerja_id" 
                        placeholder="-- Pilih Satu --"
                        :options="mrUnitKerja" 
                        :disabled="!isAdd"
                        :reduce="v=>v.value" 
                      />
                    </b-overlay>
                    <VValidate 
                      name='Group' 
                      v-model="row.ms_unit_kerja_id"
                      rules="required"
                    />
                  </b-form-group>
                </b-col>
              </template>
            </b-row>
            <b-row>
              <b-col md=6>
                <b-form-group>
                  <template #label>
                    Nama <span class="text-danger mr5">*</span>
                  </template>
                  <b-input 
                    type="text"
                    v-model="row.ms_pjrtu"
                  />
                  <VValidate 
                    name="Nama PJRTU" 
                    v-model="row.ms_pjrtu" 
                    :rules="mrValidation.ms_pjrtu" 
                  />
                </b-form-group>
              </b-col>
              <b-col md=6>
                <b-form-group>
                  <template #label>
                    Jabatan <span class="text-danger mr5">*</span>
                  </template>
                  <b-input 
                    type="text"
                    v-model="row.ms_jabatan"
                  />
                  <VValidate 
                    name="Jabatan" 
                    v-model="row.ms_jabatan" 
                    :rules="mrValidation.ms_jabatan" 
                  />
                </b-form-group>
              </b-col>
              <b-row>
                <b-col
                md="6"
              >
                <b-form-group>
                  <template #label>
                    Status <span class="text-danger mr5">*</span>
                  </template>
                  <b-form-radio-group
                    id="radio-group-2"
                    v-model="row[statusKey]"
                    name="radio-group-2"
                  >
                    <b-form-radio value=1>Active</b-form-radio>
                    <b-form-radio value=2>Inactive</b-form-radio>
                  </b-form-radio-group>
                  <VValidate 
                    name="Status" 
                    v-model="row[statusKey]" 
                    :rules="mrValidation[statusKey]" 
                  />
                </b-form-group>
              </b-col>
              </b-row>
            </b-row>
            <div class="row mt-4">
              <div class="col-md-2 offset-md-10">
                <button type="submit" class="btn btn-block btn-rounded  btn-info">Submit</button>
              </div>
            </div>
          </form>
        </ValidationObserver>

      </div>
    </div>
      
    </div>
  </div>
</template>

<script>
import GlobalVue from '@libs/Global'
import PageTitle from '@view/include/PageTitle'
import CardList from '@/components/CardList'
import Gen from '@libs/Gen.js'

let _ = global._;

export default{
  extends:GlobalVue,
  components:{PageTitle, CardList},
  data(){
    return {
      idKey:'ms_id',
      statusKey: 'ms_is_active',
      fields:[
        { key:'number', label:'#' },
        { key:'ms_kategori', label:'Kategori Jabatan', is_custom: true },
        { key:'mo_name', label:'Kantor', is_custom: true},
        { key:'ms_pjrtu', label:'Nama' },
        { key:'ms_jabatan', label:'Jabatan' },
        { key:'ms_is_active', label:'Status', is_custom: true },
        { key:'aksi', is_custom:true, style:{width:'170px'} },
      ],
      mrKantor:[],
      mrUnitKerja:[],
      status: [
        {id : 1, name: "Active"},
        {id: 2, name: "Inactive"}
      ],
      loadingDivisi: false,
      isKantorPusat: false,
      mrKategori: []
    }
  },
  mounted(){
    this.apiGet()
  },
  watch:{
    $route(){
      this.apiGet()
    },
    "row.ms_kategori"(newVal){
      if(newVal == 'kadiv'){
        this.row.ms_office_id = this.mrKantor.find(v => v.level == 'pusat').value;
      }
    }
  },
  methods: {
    getDivisi(){
       const ms_office_id = this.row.ms_office_id;
        const payload = {
          data: {
            type: "getDivisi",
            ms_office_id 
          }
        }
          this.loadingDivisi=true
          Gen.apiRest("/do/" + this.modulePage, payload, "POST").then(
            res => {
              this.loadingDivisi=false
              this.isKantorPusat = res.data.data

              if(!res.data.data) this.row.ms_unit_kerja_id = null;
            }
          ).catch(err => {
            this.loadingDivisi=false
            console.log(err)
          })
      }
  },
  computed: {
    isPusat(){
      return _.filter(this.mrKantor, (v) => {
        return v.value == this.row.ms_office_id && v.level=='pusat';
      }).length;
    }
  }
}
</script>